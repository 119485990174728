import * as XLSX from "xlsx";

const XmlToJson = async (cursos: string): Promise<any[]> => {
  const response = await fetch(cursos);
  const arrayBuffer = await response.arrayBuffer();
  const data = new Uint8Array(arrayBuffer);
  const workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(worksheet);

  return JSON.parse(JSON.stringify(json, null, 2));
};

export default XmlToJson;
