const theme = {
  title: "light",
  colors: {
    primary: "#FD7702",
    secondary: "#222222",
    background: "#f5f5f5",
    backgroundAlt: "#A0A6F2",
    text: "#333",
    textAlt: "#fff",
    lightgray: "#C4C4C4",
  },
}

export default theme