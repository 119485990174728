import React, { useState } from "react";
import {
  Container,
  CoursesMenu,
  Limiter,
  LogoSection,
  NavItem,
  NavList,
  SideMenu /* ButtonSection */,
} from "./styles";
// import { BtnPrimary, BtnSecondary } from "../Buttons/styles"
// import { Link } from "react-router-dom"
// import { MdSearch, MdShoppingCart } from "react-icons/md"
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdAdd, IoMdArrowDown, IoMdClose } from "react-icons/io";
import { catalog } from "../../helpers/infos";
import { redirectTo } from "../../pages/home/functions";
import { BtnPrimaryLg } from "../Buttons/styles";

interface Props {
  toggleTheme?(): void;
}

const Header: React.FC<Props> = () => {
  const [menu, setToggleMenu] = useState(false);
  const [expand, setExpand] = useState(false);
  const [courses, setToggleCourses] = useState(false);
  // const logged = false
  return (
    <Container>
      <Limiter>
        <LogoSection>
          <img src="/images/logotipo.png" alt="Logotipo Uniholi" />
        </LogoSection>
        <NavList className="toHide">
          <NavItem>
            <span onClick={() => setToggleCourses(true)}>
              Cursos <IoMdAdd style={{ fontSize: 24 }} />
            </span>
          </NavItem>
          <NavItem>
            <a href="#atendimento">Experimente</a>
          </NavItem>
          <NavItem>
            <a href="#contato">Contato</a>
          </NavItem>
          {/* <NavItem>
            <MdSearch size={24} />
          </NavItem> */}
        </NavList>
        <div className="toHide"></div>
        <button>
          <GiHamburgerMenu
            style={{ fontSize: 40 }}
            onClick={() => setToggleMenu(true)}
          />
        </button>

        <SideMenu
          menu={menu}
          expand={expand}
          onClick={(e) =>
            e.currentTarget === e.target
              ? (setToggleMenu(false), setExpand(false))
              : null
          }
        >
          <ul>
            <li className="close" onClick={() => setToggleMenu(false)}>
              <IoMdClose />
            </li>
            <li onClick={(e) => e.stopPropagation()} >
              <button
                onClick={(e) =>
                  e.currentTarget === e.target ? setExpand((e) => !e) : null
                }
              >
                Cursos <IoMdArrowDown />
              </button>
            </li>
            <li className="toggleable">
              {catalog.map((course) => (
                <div className="mobileCourseOption">
                  <span>{course[0]}</span>
                  <span className="price">{course[1]}</span>
                  <BtnPrimaryLg
                    onClick={() =>
                      redirectTo(
                        `https://wa.me/5511980982793?text=Olá, tenho interesse em ${course[0]}`
                      )
                    }
                  >
                    Saiba Mais
                  </BtnPrimaryLg>
                </div>
              ))}
            </li>
            <li onClick={(e) => e.stopPropagation()} >
              <a href="#atendimento" onClick={() => setToggleMenu(false)}>
                Experimente
              </a>
            </li>
            <li onClick={(e) => e.stopPropagation()} >
              <a href="#contato" onClick={() => setToggleMenu(false)}>
                Contato
              </a>
            </li>
          </ul>
        </SideMenu>

        <CoursesMenu
          menu={courses}
          onClick={(e) =>
            e.currentTarget === e.target ? setToggleCourses(false) : null
          }
        >
          <ul>
            <li className="close" onClick={(e) => setToggleCourses(false)}>
              <IoMdClose style={{ fontSize: 24 }} />
            </li>
            {catalog.map((course) => (
              <li
                className="course"
                onClick={() =>
                  redirectTo(
                    `https://wa.me/5511980982793?text=Olá, tenho interesse em ${course[0]}`
                  )
                }
              >
                <div className="name">{course[0]}</div>
                <div className="price">
                  <div className="value">{course[1]}</div>
                  <div className="action">
                    <BtnPrimaryLg
                      onClick={() =>
                        redirectTo(
                          `https://wa.me/5511980982793?text=Olá, tenho interesse em ${course[0]}`
                        )
                      }
                    >
                      Saiba Mais
                    </BtnPrimaryLg>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </CoursesMenu>

        {/* <div>
          {logged ? (
            <div>cavalo</div>
          ) : (
            <ButtonSection>
              <NavItem>
                <Link to="/">
                  <MdShoppingCart size={24} />
                </Link>
              </NavItem>
              <Link to="login">
                <BtnSecondary>Entrar</BtnSecondary>
              </Link>
              <Link to="register">
                <BtnPrimary>Criar conta</BtnPrimary>
              </Link>
            </ButtonSection>
          )}
        </div> */}
      </Limiter>
    </Container>
  );
};

export default Header;
