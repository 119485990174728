// import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import usePersistedState from "./helpers/usePersistedState";
import { ThemeProvider, DefaultTheme } from "styled-components";
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";
import "./styles/fonts/fonts.css";
import { Home } from "./pages/home/home";
// import { Catalog } from "./pages/catalog"
import { Layout } from "./components/Layout";
// import { Login } from "./pages/login/login"
// import { Register } from "./pages/register/register"

const App = () => {
  const [theme, setTheme] = usePersistedState<DefaultTheme>("theme", light);

  const toggleTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home toggleTheme={toggleTheme} />} />
            <Route path="*" element={<Home toggleTheme={toggleTheme} />} />
            {/* <Route path="catalog" element={<Catalog />} /> */}
          </Route>
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
        </Routes>
      </ThemeProvider>
    </Router>
  );
};

export default App;
