import styled from "styled-components"

interface Props {
  width?: string
}

export const BtnPrimary = styled.button<Props>`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textAlt};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: ${(props) => props.width || "auto"};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`

export const BtnSecondary = styled(BtnPrimary)`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`

export const BtnPrimaryLg = styled(BtnPrimary)`
  width: 100%;
  padding: 16px 24px;
  font-size: 24px;
`

export const BtnSecondaryLg = styled(BtnSecondary)`
  width: 100%;
  padding: 16px 24px;
  font-size: 24px;
`

export const BtnSocial = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: ${(props) => props.width || "auto"};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  * + * {
    margin-left: 8px;
  }
`

export const BtnSocialSm = styled(BtnSocial)`
  width: auto;
  padding: 16px;
  font-size: 24px;
`
