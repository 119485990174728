import React from "react"
import Header from "../Header"
// import Footer from "../Footer"
import { Outlet } from "react-router-dom"
import GlobalStyle from "../../styles/global"

export const Layout: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Outlet />
    </>
  )
}
