import styled from "styled-components";

interface Links {
  link: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: fit-content;
  padding: 0 20px;
  z-index: 500;
  color: ${(props) => props.theme.colors.text};

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /* > div + div {
      margin-left: 10%;
    } */
  }

  .swiper {
    height: 100%;
  }

  .mobileCarousel {
    display: none;
  }

  @media only screen and (max-width: 1100px) {
    .swiper:not(.mobileCarousel) {
      display: none;
    }

    .mobileCarousel {
      display: flex;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 312px;
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  margin-bottom: 30px;
`;

export const Image = styled.div<Links>`
  width: 100%;
  height: 312px;
  background-image: url(${(l) => l.link});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  :hover {
    cursor: pointer;
  }
`;

export const CardInfo = styled.div`
  height: auto;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  > div + div {
    margin-top: 8px;
  }
  .paid-top {
    /* height: auto; */
  }
  .paid-bottom {
    /* height: 96px; */
  }
`;

export const InfoTop = styled.div`
  height: 96px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`;

export const InfoBottom = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 36px;
  font-weight: bold;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #2121b5;
`;

export const Creator = styled.p`
  font-size: 16px;
  color: #666666;
  .highlight {
    color: #2121b5;
    font-weight: bold;
  }
`;

export const Text = styled(Creator)`
  color: #000;
`;
