import styled from "styled-components"

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 4px 0;
  * + * {
    margin-top: 20px;
  }

  h1 {
    text-shadow: 0 0 16px #0000007f;
    text-align: center;
  }
`

export const Line = styled.hr`
  width: 100%;
  height: 2px;
  color: gray;
  border: none;
  background-color: gray;
`
