const theme = {
  title: "dark",
  colors: {
    primary: "#7159c1",
    secondary: "#f2f2f2",
    background: "#1d1d1d",
    backgroundAlt: "#1d1d1d",
    text: "#f2f2f2",
    textAlt: "#333",
    lightgray: "#C4C4C4",
  },
}

export default theme