import { redirectTo } from "../../pages/home/functions"
import { Container } from "./styles"

interface Props {
  imageLink: string,
  visibility: string,
  setVisibility: React.Dispatch<React.SetStateAction<string>>
  externalLink?: string
}

const ImageViewer: React.FC<Props> = (props:Props) => {
  return (
    <>
      <Container onClick={() => props.setVisibility('hidden')} visibility={props.visibility}>
        <div>
          <img src={props.imageLink} alt="fullscreen" onClick={(e) => {
            e.stopPropagation(); 
            redirectTo(props.externalLink)
          }} />

        </div>
      </Container>
    </>
  )
}

export default ImageViewer