export const catalog = [
  ["Apometria Holística+ Mesa Apométrica", " R$ 500,00 "],
  ["Aromaterapia Quântica (Mestrado)", " R$ 150,00 "],
  ["Aromaterapia Básica", " R$ 150,00 "],
  ["Cartas Xamânicas.", " R$ 200,00 "],
  ["Comandos Quânticos Avançado", " R$ 100,00 "],
  ["Cristaloterapia Quântica (mestrado)", " R$ 100,00 "],
  ["Cristaloterapia Básica.", " R$ 100,00 "],
  ["Cromoterapia Básica.", " R$ 100,00 "],
  ["Cromoterapia Quântica (mestrado)", " R$ 100,00 "],
  ["Cura Vibracional.", " R$ 100,00 "],
  ["Curso Baralho Cigano Lennormand", " R$ 200,00 "],
  ["Curso Baralho Sibilla.", " R$ 200,00 "],
  ["Curso de Holopuntura", " R$ 220,00 "],
  ["Curso de Musicoterapia Quântica+ Mesa", " R$ 200,00 "],
  ["Curso Omne quod est.", " R$ 200,00 "],
  ["Defesa Psíquica Espiritual", " R$ 50,00 "],
  ["Desenvolvendo seus dons extras sensoriais", " R$ 100,00 "],
  ["Florais de Bach.", " R$ 250,00 "],
  ["Florais de Bach Avançado.", " R$ 300,00 "],
  ["Florais de Saint German.", " R$ 250,00 "],
  ["Gtummo I e II.", " R$ 100,00 "],
  ["Jogo Místico da Oca.", " R$ 150,00 "],
  ["Lintang Gene", " R$ 50,00 "],
  ["Luz de Santa Sarah.", " R$ 50,00 "],
  ["Magia Angelical.", " R$ 150,00 "],
  ["Magia cigana.", " R$ 400,00 "],
  ["Magia das Deusas.", " R$ 100,00 "],
  ["Magia das Velas", " R$ 100,00 "],
  ["Magia, Proteção e Contra Magia", " R$ 150,00"],
  ["Mesa Radiônica da Grande Fraternidade Branca", " R$ 500,00 "],
  ["Mesa Radiônica das Bruxas", " R$ 200,00 "],
  ["Mesa Radiônica de Santa Sarah + Mesa Dadomancia", " R$ 500,00 "],
  ["Mesa Radiônica de Umbanda", " R$ 400,00 "],
  ["Mesa Radiônica de Vidas Anteriores", " R$ 400,00 "],
  ["Mesa Radiônica do Xamanismo Sagrado", " R$ 400,00 "],
  ["Mesa Radiônica dos Anjos.", " R$ 400,00 "],
  ["Mesa Radiônica dos Oráculos", " R$ 400,00 "],
  ["Mesa Radiônica Galáctica.", " R$ 400,00 "],
  ["Musicoterapia Quântica + Mesa", " R$ 200,00 "],
  ["Omne Quod Est", " R$ 200,00 "],
  ["Oráculo da Deusa", " R$ 200,00 "],
  ["Poder da Abundância e Prosperidade", " R$ 100,00 "],
  ["Poder do Dragão.", " R$ 100,00 "],
  ["Radiestesia e Radiônica.", " R$ 150,00 "],
  ["Runas das Bruxas", " R$ 130,00 "],
  ["Terapia Arcturiana.", " R$ 100,00 "],
  ["Terapia do amor + mesa Radiônica", " R$ 100,00 "],
  ["Terapia dos Anjos.", " R$ 100,00 "],
  ["Terapia Luz Lyriana+ Mesa", " R$ 100,00 "],
  ["Terapia Multidimensional", " R$ 150,00 "],
  ["Terapia Pleiadiana+ Mesa Radiônica", " R$ 100,00 "],
  ["Terapia Sistêmica Vidas Anteriores", " R$ 150,00 "],
  ["Terapia Xamânica", " R$ 150,00 "],
  ["TVP", " R$ 100,00 "],
];
