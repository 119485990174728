import styled from "styled-components";


export const Wrapper = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  img {
    height: 60px;
    width: 60px;
    :hover {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1100px) {
    bottom: 16px;
    right: 16px;
    width: 40px;
    width: 40px;
    img {
      height: 40px;
      width: 40px;
    }
  }
`