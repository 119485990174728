import { createGlobalStyle } from "styled-components"


export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-image: url('/images/fundo_uniholi.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 0 0;
    background-size: cover;
    font-size: 16px;
    color: ${(props) => props.theme.colors.text};
    font-family: Roboto;
    font-smooth: always;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
  }

  h1 {
    font-size: 40px;
    color: #2121B5;
    font-family: 'nexa-script';
    font-weight: 900;
    text-shadow: 0 0 16px #0000007f
  }

  @media only screen and (max-width: 1100px) {
    font-size: 36px;
  }
`
