import styled from "styled-components";

interface MenuProps {
  menu: boolean;
  expand?: boolean;
}

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  height: 72px;
  width: 100%;
  padding: 0 20px;
  z-index: 501;
  border-bottom: 1px solid #000;
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
`;

export const Limiter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 1200px;
  z-index: 100;
  > div {
    flex: 1;
  }

  > button {
    display: none;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 36px;
    justify-content: space-between;

    .toHide {
      display: none;
    }
    > div {
      flex: 0;
    }

    > button {
      display: flex;
      border: 0;
      background: none;
      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const LogoSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  img {
    /* max-width: 150px; */
    width: auto;
    max-height: 70px;
  }
`;

export const NavSection = styled.nav`
  display: flex;
  height: 100%;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: unset;
  margin: unset;
  justify-self: center;
  overflow: auto;
  li + li {
    margin-left: 24px;
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-weight: bold;
  font-size: 18px;
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  button {
    display: flex;
  }
`;
export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  * + * {
    margin-left: 16px;
  }
`;

export const SideMenu = styled.div<MenuProps>`
  background-color: #00000090;
  visibility: ${({ menu }) => (menu ? "visible" : "hidden")};
  opacity: ${({ menu }) => (menu ? "1" : "0")};
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  ul {
    width: 300px;
    height: 100vh;
    background-color: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    transform: ${({ menu }) => (menu ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s ease-in-out;
    overflow: scroll;

    li {
      display: flex;
      width: 100%;
      padding: 8px 16px;
      > span, button {
        width: 100%;
        display: flex;
        align-items: center;
        svg {
          transform: ${({ expand }) =>
            expand ? "rotate(180deg)" : "rotate(0deg)"};
          transition: transform 0.3s ease-in-out;
        }
      }
      button {
        background-color: white;
        font-size: inherit;
        font-family: inherit;
        border: none;
      }
    }

    .toggleable {
      flex-direction: column;
      flex: ${({ expand }) => (expand ? "fit-content" : 0)};
      padding: 0 8px;
      overflow: ${({ expand }) => (expand ? "initial" : "hidden")};
      transition: flex 1s ease-in-out;
      font-size: 20px;

      .mobileCourseOption {
        display: flex;
        flex-direction: column;
        padding: 8px 12px;
        border: 1px solid grey;
        border-radius: 4px;
        .price {
          text-align: right;
          font-weight: bold;
        }
      }

      .mobileCourseOption + .mobileCourseOption {
        margin-top: 12px;
      }
    }

    .close {
      justify-content: flex-end;
      cursor: pointer;
    }
  }
`;

export const CoursesMenu = styled(SideMenu)`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    width: 100vh;
    max-width: 1230px;
    height: 95vh;
    position: relative;
    transform: none;
    border-radius: 4px;
    padding: 8px;
  }

  .course + .course {
    margin-top: 16px;
  }

  .course {
    border: 1px solid grey;
    border-radius: 8px;
  }

  li {
    font-size: 28px;
    div {
      display: flex;
      align-items: center;
    }
    .name {
      flex: 1;
    }
    .value {
      font-size: 28px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
