import styled from "styled-components";

interface Props {
  visibility?: string,
  imageLink?: string
}

export const Container = styled.div<Props>`
  visibility: ${(props) => props.visibility ? props.visibility : 'hidden'};
  width: 100%;
  height: 100%;
  z-index: 502;
  background-color: #00000090;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    max-width: 90vw;
    max-height: 90vh;
    width: fit-content;
    height: auto;
  }

  img {
    object-fit: scale-down;
    max-height: 90vh;
    max-width: 90vw;
  }
`;