import styled from "styled-components";
import { BtnPrimary } from "../../components/Buttons/styles";

export const HeadingBanner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;

  img {
    max-width: 1230px;
    height: auto;
    width: 100%;
  }
`;

export const BannerButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: "nexa-script";
  font-size: 24px;
  font-weight: bolder;
  background-color: #fff;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 1230px;
    width: 100%;
  }

  *:not(svg path) {
    color: #fd7702;
  }

  a {
    display: flex;
    align-items: center;
  }
  a + a {
    margin-left: 20px;
  }

  @media only screen and (max-width: 640px) {
    span {
      display: none;
    }
    div {
      justify-content: center;
    }
  }
`;

export const VideoSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 28px;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    > * + * {
      margin-left: 0px;
    }
  }
`;

export const VideoSecondSection = styled(VideoSection)`
  flex-direction: column;
  > * + * {
    margin-left: 0px;
    margin-top: 28px;
  }
  > div {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

export const FinalSection = styled(VideoSecondSection)`
  > div {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex: 1;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  border: 0px;
  overflow: hidden;
  box-shadow: 0 0 16px #00000040;
  height: fit-content;

  iframe {
    display: flex;
    flex: 1;
    border: none;
  }

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const VideoContainerRatio = styled(VideoContainer)`
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const TextScrollable = styled.div`
  display: flex;
  flex-direction: column;
  width: 36%;
  height: 512px;
  padding: 20px 0px;

  * + * {
    margin-top: 12px;
  }

  h1 {
    text-align: center;
    font-size: 36px;
  }

  div {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #fd7702 #f5f5f5;
    padding-right: 4px;
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f5f5f5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #fd7702;
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #f07000;
    }
  }

  .citation {
    margin-left: 8px;
    font-style: italic;
    color: #444;
    text-shadow: 0px 1px 5px #0000007f;
    text-align: justify;
  }

  .citation-owner {
    font-weight: bold;
    margin-left: 8px;
  }

  @media only screen and (max-width: 1100px) {
    width: 100%;
    margin: 0px;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const DoubleColumns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .column {
    display: flex;
    flex-direction: row;
    width: 50%;
    padding-right: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .singleColumn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      max-height: 596px;
    }
  }

  .column + div {
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 0px !important;
  }

  .buttons {
    justify-content: space-between !important;
    flex-direction: column;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    > * + * {
      margin-top: 40px;
    }

    .column {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-left: 0px !important;
      padding-right: 0px;

      img {
        width: 100%;
        height: auto;
      }

      button {
        margin-top: 20px;
      }
    }
    .buttons {
      align-items: center;
    }
  }
`;

export const BtnLinksSocial = styled(BtnPrimary)`
  width: 100%;
  padding: 0px;
  font-size: 40px;
  font-family: "nexa-script";
  background-color: white;
  border: 0px;
  color: #2121b5;
  display: flex;
  overflow: hidden;
  max-width: 500px;
  path {
    margin: -10px;
  }

  div {
    padding: 16px 24px;
    display: flex;
    align-items: center;
  }

  .iconSearch {
    width: 80px;
    height: 100%;
    background-color: #2121b5;
  }

  div:not(.iconSearch) {
    flex: 1;
    flex-direction: row;
    justify-content: center;
    * + * {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 1100px) {
    font-size: 32px;
    letter-spacing: -3px;

    .iconSearch {
      width: 60px;
      background-color: #2121b5;
    }

    div {
      padding: 16px;
    }
  }
`;
