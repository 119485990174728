import styled from "styled-components"

export const HomeSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 1280px;
  width: 100%;
  /* margin: 0 auto; */
  padding: 72px 24px 32px 24px;
  /* background: ${(props) => props.theme.colors.background}; */
  img:hover {
    cursor: pointer;
  }
`