import React from "react"
import { Container, Line } from "./styles"

interface Props {
  children: React.ReactNode
}

export const HomeTitle: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Line />
        <h1>{children}</h1>
      <Line />
    </Container>
    )
}
